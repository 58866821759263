<script lang="ts" setup>
import type { ProductDetailsType } from '~/api/product';

const props = defineProps<{
  product: ProductDetailsType;
}>();

const { t } = useI18n();

const enabled = computed(
  () =>
    !!(
      props.product.wayOfApplication?.picturePath ||
      props.product.wayOfApplication?.text
    ),
);
</script>

<template>
  <section
    v-if="product.wayOfApplication && enabled"
    class="productModeOfApplicationSection"
  >
    <NuxtImg
      v-if="product.wayOfApplication.picturePath"
      format="webp"
      :sizes="IMAGE_SIZES.adaptiveHalfScreen"
      :src="product.wayOfApplication.picturePath"
      :alt="product.wayOfApplication.text || ''"
      class="productModeOfApplicationSection__image"
    />

    <div class="productModeOfApplicationSection__container content-container">
      <div class="productModeOfApplicationSection__content">
        <h2 class="productModeOfApplicationSection__title">
          {{ t('title') }}
        </h2>
        <p
          v-if="product.wayOfApplication.text"
          class="productModeOfApplicationSection__description"
        >
          {{ product.wayOfApplication.text }}
        </p>
      </div>
    </div>
  </section>
</template>

<i18n>
ru:
  title: Способ применения

en:
  title: Mode of application
</i18n>

<style lang="scss">
.productModeOfApplicationSection {
  display: flex;
  position: relative;

  @include mq('sm') {
    flex-direction: column;
  }

  &__image {
    display: flex;
    width: 50%;
    height: 100svh;
    position: sticky;
    top: 0;
    bottom: 0;
    object-fit: cover;

    @include mq('sm') {
      width: 100%;
      height: auto;
      position: static;
    }
  }

  &__container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 20px;
    position: relative;

    @include mq('sm') {
      min-height: 390px;
      width: 100%;
      padding: 40px 16px;
    }
  }

  &__content {
    max-width: 466px;
    width: 100%;
  }

  &__title {
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.2px;
    color: #121212;
    margin-top: 0;
    margin-bottom: 24px;

    @include mq('sm') {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 12px;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #121212;
    white-space: pre-wrap;
    margin: 0;
    opacity: 0.4;
  }
}
</style>
