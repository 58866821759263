<script lang="ts" setup>
import type { ProductDetailsType } from '~/api/product';

const props = defineProps<{
  product: ProductDetailsType;
}>();

const { t } = useI18n();

const enabled = computed(
  () =>
    !!(
      props.product.action?.picturePath ||
      props.product.action?.text ||
      props.product.action?.actions.length
    ),
);
</script>

<template>
  <section v-if="product.action && enabled" class="productEffectSection">
    <NuxtImg
      v-if="product.action.picturePath"
      format="webp"
      :sizes="IMAGE_SIZES.adaptiveHalfScreen"
      :src="product.action.picturePath"
      :alt="product.action.text || ''"
      class="productEffectSection__image"
    />

    <div class="productEffectSection__container content-container">
      <div class="productEffectSection__content">
        <h2 class="productEffectSection__title">{{ t('title') }}</h2>
        <p v-if="product.action.text" class="productEffectSection__description">
          {{ product.action.text }}
        </p>

        <div class="productEffectSection__list">
          <div
            v-for="(item, index) in product.action.actions"
            :key="index"
            class="productEffectSection__item"
          >
            <NuxtImg
              v-if="item.iconPath"
              :src="item.iconPath"
              :alt="item.name || ''"
              width="65"
              class="productEffectSection__itemImage"
            />
            <div>
              <h3 v-if="item.name" class="productEffectSection__itemTitle">
                {{ item.name }}
              </h3>
              <p
                v-if="item.description"
                class="productEffectSection__itemDescription"
              >
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<i18n>
ru:
  title: Описание

en:
  title: Effect
</i18n>

<style lang="scss">
.productEffectSection {
  display: flex;
  position: relative;

  @include mq('sm') {
    flex-direction: column;
    height: auto;
  }

  &__image {
    display: flex;
    width: 50%;
    height: 100svh;
    position: sticky;
    top: 0;
    bottom: 0;
    object-fit: cover;

    @include mq('sm') {
      width: 100%;
      height: auto;
      position: static;
    }
  }

  &__container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 34px 20px;

    @include mq('sm') {
      width: 100%;
      padding: 34px 16px 56px;
    }
  }

  &__content {
    max-width: 516px;
    width: 100%;
  }

  &__title {
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.2px;
    color: #121212;
    margin-top: 0;
    margin-bottom: 19px;

    @include mq('sm') {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #121212;
    white-space: pre-wrap;
    margin-top: 0;
    margin-bottom: 48px;

    @include mq('sm') {
      margin-bottom: 40px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @include mq('sm') {
      gap: 40px;
    }
  }

  &__item {
    display: flex;
    gap: 40px;
    align-items: center;

    @include mq('sm') {
      gap: 32px;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__itemImage {
    display: block;
    flex: 1 0 65px;
  }

  &__itemTitle {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #121212;
    margin-top: 0;
    margin-bottom: 8px;
  }

  &__itemDescription {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #121212;
    opacity: 0.6;
    margin: 0;
    white-space: pre-wrap;
  }
}
</style>
