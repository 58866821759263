<script lang="ts" setup>
import type { ProductDetailsType } from '~/api/product';
import ProductAPI from '~/api/product';
import type { ResponseError } from '~/api/utils/types';
import placeholder from '~/assets/icons/placeholder.svg?url';

const props = defineProps<{
  visible: boolean;
  product: ProductDetailsType;
}>();
const visible = toRef(props, 'visible');
const emit = defineEmits<{
  (event: 'update:visible', value: boolean): void;
}>();

const { t } = useI18n();
const commonStore = useCommonStore();
const modalStore = useModalStore();

const rating = ref(5);
const title = ref('');
const message = ref('');

const productPicture = computed(
  () =>
    commonStore.products.find((el) => el.id === props.product.id)?.picture ||
    placeholder,
);

const { execute, isValid, isLoading } = useRequest(
  ProductAPI.createReview,
  () => ({
    body: {
      title: title.value,
      text: message.value,
      rating: rating.value,
      recommend: rating.value >= 3,
    },
    params: { productId: props.product.id },
  }),
);

const [modalVisible, onToggleVisible] = useExternalModalState(visible, (v) =>
  emit('update:visible', v),
);

const onSubmit = () =>
  execute()
    ?.then(() => {
      onToggleVisible(false);
      modalStore.showSuccess({
        title: t('message.success'),
        action: t('message.action'),
      });
    })
    .catch((e: ResponseError) => {
      modalStore.showError({
        title: getFetchErrorMessage(e, t('message.error')),
        action: t('message.action'),
      });
    });
</script>

<template>
  <BaseModal
    :visible="modalVisible"
    @update:visible="onToggleVisible"
    aria-title="Оставить отзыв"
    aria-describedby="Оставить новый отзыв о продукте"
    container-class="productCreateReviewModal"
  >
    <form @submit.prevent="onSubmit">
      <h4 class="productCreateReviewModal__title">{{ t('title') }}</h4>

      <ProductCartItem
        :name="product.name"
        :code="product.code"
        :value="product.volume"
        :picture="productPicture"
        mode="history"
        small
      />

      <div class="productCreateReviewModal__card">
        <span class="productCreateReviewModal__question">
          {{ t('question') }}
        </span>
        <div class="productCreateReviewModal__rating">
          <BaseRating v-model:value="rating" large />
          <i18n-t
            keypath="review"
            tag="span"
            class="productCreateReviewModal__ratingDescription"
          >
            <TransitionGroup name="reel">
              <span :key="rating">{{ t(`rating.${rating}`) }}</span>
            </TransitionGroup>
          </i18n-t>
        </div>
        <BaseTextField
          v-model:value="title"
          :placeholder="t('form.title')"
          bordered
          class="productCreateReviewModal__input"
        />
        <BaseTextField
          v-model:value="message"
          :placeholder="t('form.message')"
          rows="1"
          bordered
          multiline
          class="productCreateReviewModal__textarea"
        />
      </div>

      <BaseButton
        dark
        :disabled="!isValid"
        :loading="isLoading"
        type="submit"
        size="s"
        class="productCreateReviewModal__submit"
      >
        {{ t('form.submit') }}
      </BaseButton>
    </form>
  </BaseModal>
</template>

<i18n>
ru:
  title: Новый отзыв
  question: Вам понравился товар?
  rating:
    '1': Ужасный
    '2': Плохой
    '3': Нормальный
    '4': Хороший
    '5': Отличный
  review: '{0} товар'
  form:
    title: Заголовок отзыва
    message: Ваш отзыв
    submit: Отправить отзыв
  message:
    success: Спасибо за ваш отзыв!\nПосле прохождения модерации он будет опубликован
    error: Во время добавления отзыва произошла ошибка
    action: Вернуться

en:
  title: New review
  question: Did you like the product?
  rating:
    '1': Terrible
    '2': Bad
    '3': Normal
    '4': Good
    '5': Excellent
  review: '{0} product'
  form:
    title: Review title
    message: Your review
    submit: Submit review
  message:
    success: Thank you for your review!\nAfter moderation, it will be published
    error: An error occurred while adding a review
    action: Return
</i18n>

<style lang="scss">
.productCreateReviewModal {
  padding: 37px 40px 32px;
  width: 100%;
  max-width: 649px;

  @include mq('sm') {
    padding: 50px 16px 32px;
    max-width: 100%;
  }

  &__title {
    margin: 0 0 24px;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #121212;

    @include mq('sm') {
      text-align: center;
      margin-bottom: 32px;
    }
  }

  &__card {
    margin-top: 32px;
    padding: 24px 20px 21px;
    border: 1px solid #e6e6e6;
    border-radius: 10px;

    @include mq('sm') {
      border: none;
      padding: 0;
      border-radius: 0;
    }
  }

  &__question {
    display: block;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: #121212;
    padding-bottom: 20px;
    border-bottom: 1px solid #e6e6e6;

    @include mq('sm') {
      font-size: 20px;
      line-height: 28px;
      padding-bottom: 18px;
    }
  }

  &__rating {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 26px;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.2px;
    color: #121212;

    @include mq('sm') {
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }

  &__ratingDescription {
    position: relative;
    display: flex;
    align-items: center;

    span {
      position: absolute;
      top: 0;
      right: 100%;
    }
  }

  &__input {
    width: 100%;
  }

  &__textarea {
    margin-top: 16px;
    width: 100%;
    resize: none;
  }

  &__submit {
    margin: 32px auto 0;
    width: 275px;
    max-width: 100%;

    @include mq('sm') {
      margin-top: 20px;
      width: 100%;
    }
  }
}
</style>
