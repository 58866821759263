<script lang="ts" setup>
import type { ProductDetailsType } from '~/api/product';

const props = defineProps<{
  product: ProductDetailsType;
}>();

const { t } = useI18n();

const enabled = computed(
  () => !!(props.product.size?.picturePath || props.product.size?.text),
);
</script>

<template>
  <section v-if="product.size && enabled" class="productSizesSection">
    <NuxtImg
      v-if="product.size.picturePath"
      format="webp"
      :sizes="IMAGE_SIZES.adaptiveHalfScreen"
      :src="product.size.picturePath"
      :alt="product.size.text || ''"
      class="productSizesSection__image"
    />

    <div class="productSizesSection__container content-container">
      <div class="productSizesSection__content">
        <h2 class="productSizesSection__title">{{ t('title') }}</h2>
        <p v-if="product.size.text" class="productSizesSection__description">
          {{ product.size.text }}
        </p>
      </div>
    </div>
  </section>
</template>

<i18n>
ru:
  title: Описание размеров

en:
  title: Size description
</i18n>

<style lang="scss">
.productSizesSection {
  display: flex;
  position: relative;

  @include mq('sm') {
    flex-direction: column;
    height: auto;
  }

  &__image {
    display: flex;
    width: 50%;
    height: 100svh;
    position: sticky;
    top: 0;
    bottom: 0;
    object-fit: cover;

    @include mq('sm') {
      width: 100%;
      height: auto;
      position: static;
    }
  }

  &__container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 34px 20px;

    @include mq('sm') {
      width: 100%;
      padding: 34px 16px 56px;
    }
  }

  &__content {
    max-width: 516px;
    width: 100%;
  }

  &__title {
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.2px;
    color: #121212;
    margin-top: 0;
    margin-bottom: 19px;

    @include mq('sm') {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #121212;
    white-space: pre-wrap;
    margin-top: 0;
    margin-bottom: 48px;

    @include mq('sm') {
      margin-bottom: 40px;
    }
  }
}
</style>
