<script lang="ts" setup>
import type {
  ProductDetailsType,
  ProductReviewInfoType,
  ProductVariantType,
} from '~/api/product';
import nameLogo from '~/assets/icons/logo/name.svg?url';

const props = defineProps<{
  product: ProductDetailsType;
  review: ProductReviewInfoType;
  selected?: ProductVariantType;
}>();
const emit = defineEmits<{
  (event: 'update:selected', value: ProductVariantType): void;
}>();
defineSlots<{
  default: () => VNode[];
}>();

const { t } = useI18n();

const prices = computed(() =>
  props.product.skus
    .map((item) => ({
      ...item,
      profit:
        item.basePrice &&
        ((item.basePrice - item.price) / item.basePrice) * 100,
    }))
    .sort((a, b) => a.price - b.price),
);
const images = computed(() =>
  props.product.picturePaths.map((el) => ({
    src: el,
    alt: props.product.name,
  })),
);

const selectVariant = (variantId: number) => {
  const variant = props.product.skus.find((el) => el.id === variantId);

  if (variant?.isAvailable) emit('update:selected', variant);
};
</script>

<template>
  <section class="productInfoSection">
    <div class="productInfoSection__visual">
      <BaseGallery
        :images="images"
        :image-parameters="{ sizes: IMAGE_SIZES.adaptiveHalfScreen }"
        autoplay
      />

      <NuxtLink to="/" class="productInfoSection__logo">
        <img :src="nameLogo" alt="Don't Touch My Skin" />
      </NuxtLink>
    </div>

    <div class="productInfoSection__container content-container">
      <div class="productInfoSection__content">
        <span class="productInfoSection__code">
          {{ product.article }}
        </span>
        <h1 class="productInfoSection__title">
          {{ product.name }}
        </h1>
        <span v-if="product.volume" class="productInfoSection__value">
          {{ product.volume }}
        </span>
        <div v-if="review.rating" class="productInfoSection__rating">
          <BaseRating :value="review.rating" readonly />
          {{
            t('rating', {
              value: review.rating,
              total: t('reviews', { n: review.total }),
            })
          }}
        </div>
        <p v-if="product.description" class="productInfoSection__description">
          <BaseComplexText :content="product.description" />
        </p>

        <div class="productInfoSection__skuSelect" v-if="prices.length > 1">
          <BaseButton
            v-for="item in prices"
            :key="item.id"
            @click="selectVariant(item.id)"
            :disabled="!item.isAvailable"
            :selected="item.id === selected?.id"
            size="s"
            class="productInfoSection__valueButton"
          >
            {{ item.distinct }}
          </BaseButton>
        </div>

        <slot />
      </div>
    </div>
  </section>
</template>

<i18n>
ru:
  reviews: '{n} отзыв | {n} отзыва | {n} отзывов'
  rating: '{value} ({total})'

en:
  reviews: '{n} review | {n} reviews'
  rating: '{value} ({total})'
</i18n>

<style lang="scss">
.productInfoSection {
  display: flex;
  position: relative;

  @include mq('sm') {
    flex-direction: column;
  }

  &__visual {
    position: sticky;
    top: 0;
    bottom: 0;
    width: 50%;
    height: 100svh;

    @include mq('sm') {
      position: relative;
      width: 100%;
      height: auto;
    }
  }

  &__logo {
    width: 227px;
    position: absolute;
    top: 24px;
    left: 35px;
    z-index: 1;

    @include mq('lg') {
      width: 139px;
      top: 21px;
      left: 16px;
    }

    img {
      width: 100%;
      display: block;
    }
  }

  &__container {
    width: 50%;
    padding: 95px 20px;

    @include mq('sm') {
      width: 100%;
      padding: 24px 16px;
    }
  }

  &__content {
    max-width: 570px;
    width: 100%;
    margin: 0 auto;
  }

  &__code {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #121212;
    opacity: 0.2;

    @include mq('sm') {
      display: none;
    }
  }

  &__title {
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.2px;
    color: #121212;
    margin-top: 7px;
    margin-bottom: 6px;

    @include mq('sm') {
      margin-top: 0;
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__value {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #121212;

    @include mq('sm') {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__rating {
    display: flex;
    gap: 16px;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;

    @include mq('sm') {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #121212;
    margin-top: 16px;
    margin-bottom: 0;
    white-space: pre-wrap;
  }

  &__skuSelect {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__valueButton {
    width: fit-content;
    max-width: 100%;
    border: 1px solid #000000;

    &:hover,
    &.baseButton_selected {
      color: #ffffff;
      border-color: #8583ba;
      background: #8583ba !important;
    }
  }
}
</style>
