import type { InferType } from 'yup';
import { array, boolean, number, object, string } from 'yup';

import request from '~/api/utils';
import { HTTPMethod } from '~/api/utils/types';

const productReviewSchema = object().shape({
  title: string().required(),
  text: string().required(),
  rating: number().required(),
  recommend: boolean().required(),
  nameUser: string().optional(),
});

export const productReviewsResponseSchemas = object()
  .shape({
    collection: array().ensure().of(productReviewSchema).required(),
    total: number().required(),
    rating: number().required(),
  })
  .required();

const skuSchema = object()
  .shape({
    id: number().required(),
    xmlId: string().required(),
    price: number().required(),
    basePrice: number().required().nullable(),
    skuCode: string().nullable(),
    distinct: string().required(),
    isAvailable: boolean().required(),
    isGift: boolean().optional(),
  })
  .required();

const productDetailsResponseSchemas = object().shape({
  product: object().shape({
    id: number().required(),
    refillId: number().required().nullable(),
    code: string().required(),
    name: string().required(),
    article: string().required(),
    description: string().defined(),
    sectionIds: array().ensure().of(number().required()).required(),
    picturePaths: array().ensure().of(string().required()).required(),
    accentColor: string().nullable(),
    volume: string().nullable(),
    seo: object({
      title: string().defined().nullable(),
      description: string().defined().nullable(),
      keywords: string().defined().nullable(),
    }).required(),
    size: object({
      text: string().defined().nullable(),
      picturePath: string().defined().nullable(),
    }).nullable(),
    wayOfApplication: object()
      .shape({
        text: string().defined().nullable(),
        picturePath: string().required().nullable(),
      })
      .nullable(),
    composition: object()
      .shape({
        text: string().defined().nullable(),
        picturePath: string().required().nullable(),
        compositions: array()
          .ensure()
          .of(
            object()
              .shape({
                name: string().defined().nullable(),
                description: string().defined().nullable(),
              })
              .required(),
          )
          .required(),
      })
      .nullable(),
    action: object()
      .shape({
        text: string().defined().nullable(),
        picturePath: string().required().nullable(),
        actions: array()
          .ensure()
          .of(
            object()
              .shape({
                name: string().defined().nullable(),
                description: string().defined().nullable(),
                iconPath: string().required().nullable(),
              })
              .required(),
          )
          .required(),
      })
      .nullable(),
    kit: object()
      .shape({ ids: array().ensure().of(number().required()).required() })
      .nullable(),
    skus: array().ensure().of(skuSchema).required(),
  }),
  review: productReviewsResponseSchemas,
  boughtWithProductIds: array().ensure().of(number().required()).required(),
  viewedProductIds: array().ensure().of(number().required()).required(),
});

const productDetailsParamsSchemas = object().shape({
  id: number().positive().required(),
});

const reviewsParamsSchema = object().shape({ productId: number().required() });

const createReviewBodySchema = object().shape({
  title: string().required(),
  text: string().required(),
  rating: number().required(),
  recommend: boolean().required(),
});

export type ProductDetailsInfoType = InferType<
  typeof productDetailsResponseSchemas
>;

export type ProductReviewInfoType = ProductDetailsInfoType['review'];

export type ProductReviewItemType = InferType<typeof productReviewSchema>;

export type ProductDetailsType = ProductDetailsInfoType['product'];

export type ProductVariantType = InferType<typeof skuSchema>;

const ProductAPI = {
  details: request.prepare({
    url: ({ id }) => `products/${id}`,
    schemas: {
      params: productDetailsParamsSchemas,
      response: productDetailsResponseSchemas,
    },
  }),
  reviews: request.prepare({
    url: ({ productId }) => `reviews/${productId}`,
    schemas: {
      params: reviewsParamsSchema,
      response: productReviewsResponseSchemas,
    },
  }),
  createReview: request.prepare({
    url: ({ productId }) => `reviews/${productId}`,
    method: HTTPMethod.post,
    schemas: { params: reviewsParamsSchema, body: createReviewBodySchema },
  }),
};

export default ProductAPI;
