<script lang="ts" setup>
import { Autoplay, Controller } from 'swiper/modules';
import type { Swiper as SwiperClass } from 'swiper/types';
import { Swiper, SwiperSlide } from 'swiper/vue';

withDefaults(
  defineProps<{
    images: { src: string; alt?: string }[];
    imageParameters?: { width?: number | string; sizes?: string };
    autoplay?: boolean;
    cover?: boolean;
  }>(),
  { cover: true },
);
const emits = defineEmits<{
  (event: 'select-slide', index: number): void;
}>();

const activeSlideIndex = defineModel<number>('activeSlideIndex', {
  default: 0,
});

const { t } = useI18n();

const controlledSwiper = ref<SwiperClass>();
const isStart = ref(false);
const isEnd = ref(false);

const setControlledSwiper = (swiper: SwiperClass) => {
  controlledSwiper.value = swiper;
};

const onUpdateNavigation = (event: SwiperClass) => {
  isStart.value = event.isBeginning;
  isEnd.value = event.isEnd;
};

const onChangeSlide = (event: SwiperClass) => {
  activeSlideIndex.value = event.activeIndex;
  onUpdateNavigation(event);
};

onDeactivated(() => {
  controlledSwiper.value?.destroy();
});
</script>

<template>
  <div class="baseGallery">
    <Swiper
      :initial-slide="activeSlideIndex"
      :slides-per-view="1"
      :pagination="{ clickable: true }"
      :modules="[...(autoplay ? [Autoplay] : []), Controller]"
      :controller="{ control: controlledSwiper }"
      @swiper="setControlledSwiper"
      @init="onUpdateNavigation"
      @reach-beginning="onUpdateNavigation"
      @reach-end="onUpdateNavigation"
      @slide-change-transition-end="onChangeSlide"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: false,
      }"
      :resistance-ratio="0"
      class="baseGallery__slider"
    >
      <SwiperSlide v-for="(item, index) in images" :key="item.src">
        <NuxtImg
          @click="emits('select-slide', index)"
          format="webp"
          :src="item.src"
          :alt="item.alt"
          class="baseGallery__slide"
          :class="{ baseGallery__slide_cover: cover }"
          v-bind="imageParameters"
        />
      </SwiperSlide>
    </Swiper>

    <div class="baseGallery__controller">
      <button
        @click="controlledSwiper?.slidePrev()"
        :disabled="isStart"
        class="baseGallery__navigate"
        :aria-label="t('prev')"
      >
        <SvgoSimpleArrow filled />
      </button>
      <span class="baseGallery__counter">
        {{ t('counter', [activeSlideIndex + 1, images.length]) }}
      </span>
      <button
        @click="controlledSwiper?.slideNext()"
        :disabled="isEnd"
        class="baseGallery__navigate"
        :aria-label="t('next')"
      >
        <SvgoSimpleArrow filled />
      </button>
    </div>
  </div>
</template>

<i18n>
ru:
  prev: Предыдущий изображение
  next: Следующий изображение
  counter: '{0} / {1}'

en:
  prev: Previous image
  next: Next image
  counter: '{0} / {1}'
</i18n>

<style src="swiper/css" />
<style lang="scss">
.baseGallery {
  width: 100%;
  height: 100%;
  position: relative;

  &__slider {
    height: 100%;
  }

  &__slide {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;

    @include mq('sm') {
      height: auto;
      aspect-ratio: 375 / 443;
    }

    &_cover {
      object-fit: cover;
    }
  }

  &__controller {
    display: flex;
    align-items: center;
    position: absolute;
    right: 18px;
    bottom: 10px;
    z-index: 1;
    color: #e6e6e6;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.5px;
    gap: 18px;
  }

  &__navigate {
    background: none;
    padding: 0;
    border: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition:
      background-color 0.3s ease,
      opacity 0.3s ease;

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }

    &:disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    &:first-child {
      transform: rotate(180deg);
    }

    svg {
      width: 30px;
      height: 100%;
    }
  }

  &__counter {
    display: block;
    min-width: 33px;
    text-align: center;
  }
}
</style>
