<script lang="ts" setup>
import ProductAPI from '~/api/product';

const { t } = useI18n();
const commonStore = useCommonStore();
const route = useRoute();
const getImage = useImage();
const dataLayer = useDataLayer();

const commonProduct = commonStore.products.find(
  (el) => el.code === route.params.slug,
);

if (!commonProduct) {
  throw createError({ statusCode: 404, statusMessage: 'Page Not Found' });
}

const { data, error } = await useAsyncData(
  `/products/${commonProduct.id}`,
  () => ProductAPI.details({ params: { id: commonProduct.id } }),
);

if (error.value || !data.value) {
  throw createError({ statusCode: 404, statusMessage: 'Page Not Found' });
}

const selectedSku = ref(
  data.value?.product.skus.find((item) => item.isAvailable),
);
const isRefillModeSelected = ref(false);

const popularProducts = computed(() =>
  commonStore.products.filter((el) =>
    data.value?.viewedProductIds.includes(el.id),
  ),
);
const similarProducts = computed(() =>
  commonStore.products.filter((el) =>
    data.value?.boughtWithProductIds.includes(el.id),
  ),
);
const kitProducts = computed(() =>
  commonStore.products.filter((el) =>
    data.value?.product.kit?.ids.includes(el.id),
  ),
);
const refillProduct = computed(() =>
  data.value?.product.refillId
    ? commonStore.products.find(
        (el) => el.id === data.value?.product.refillId && el.isAvailable,
      )
    : undefined,
);

if (data.value) {
  useSchemaOrg([
    defineProduct({
      name: data.value.product.name,
      description: data.value.product.description,
      image: data.value.product.picturePaths.map((el) => getImage(el)),
      offer: data.value.product.skus.length
        ? { price: priceFormat(data.value.product.skus[0].price) }
        : {},
    }),
  ]);
}

onMounted(() => {
  const firstSku = data.value?.product.skus[0];
  if (!firstSku) return;

  dataLayer.append(
    {
      event: 'detailview',
      ecommerce: {
        detail: {
          actionField: { list: '' },
          products: [
            {
              id: firstSku.id,
              xmlId: firstSku.xmlId,
              price: firstSku.price,
              name: firstSku.distinct,
              position: 1,
            },
          ],
        },
      },
    },
    ['user'],
  );
});
</script>

<template>
  <main class="productPage">
    <template v-if="data">
      <Head>
        <Title v-if="data.product.seo.title">
          {{ data.product.seo.title }}
        </Title>
        <Meta
          v-if="data.product.seo.description"
          name="description"
          :content="data.product.seo.description"
        />
        <Meta
          v-if="data.product.seo.keywords"
          name="keywords"
          :content="data.product.seo.keywords"
        />
        <Meta
          v-if="data.product.seo.title"
          property="og:title"
          :content="data.product.seo.title"
        />
        <Meta
          v-if="data.product.seo.description"
          property="og:description"
          :content="data.product.seo.description"
        />
      </Head>

      <div class="productPage__content">
        <ProductInfoSection
          :product="data.product"
          :review="data.review"
          v-model:selected="selectedSku"
        >
          <ProductSubscription
            v-if="refillProduct"
            v-model:checked="isRefillModeSelected"
            :product="refillProduct"
            class="productPage__subscription"
          />
        </ProductInfoSection>
        <ProductEffectSection :product="data.product" />
        <ProductCompositionSection :product="data.product" />
        <ProductModeOfApplicationSection :product="data.product" />
        <ProductSizesSection :product="data.product" />

        <ProductPurchaseWidget
          :variants="data.product.skus"
          :product="data.product"
          :selected="selectedSku"
          :refill="isRefillModeSelected"
        />
      </div>
      <div class="productPage__additional">
        <ProductSimilarProducts
          :products="kitProducts"
          :title="t('section.kit')"
        />
        <ProductSimilarProducts
          :products="popularProducts"
          :title="t('section.popular')"
        />
        <ProductSimilarProducts
          :products="similarProducts"
          :title="t('section.similar')"
        />
      </div>
      <ProductReviewsSection
        :product="data.product"
        :reviews="data.review.collection"
      />
      <SectionSubscribe />
    </template>
  </main>
</template>

<i18n>
ru:
  section:
    kit: В набор входят
    popular: Популярные продукты
    similar: С этим продуктом часто покупают

en:
  section:
    kit: The kit includes
    popular: Popular products
    similar: Frequently bought with this item
</i18n>

<style lang="scss">
.productPage {
  $self: &;

  &__content {
    position: relative;

    & > section:nth-child(2n) {
      background-color: #e8e8e8;
    }

    & .content-container {
      @include mq('none', 'sm') {
        padding-bottom: 124px;
      }
    }
  }

  &__additional {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &:empty {
      display: none;
    }
  }

  &__subscription {
    margin-top: 56px;
  }

  @include mq('sm') {
    & + .footer {
      padding-bottom: 55px;
    }
  }
}
</style>
