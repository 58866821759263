<script lang="ts" setup>
import type { ProductDetailsType, ProductReviewItemType } from '~/api/product';

const props = defineProps<{
  product: ProductDetailsType;
  reviews: ProductReviewItemType[];
}>();

const { t } = useI18n();
const userStore = useUserStore();

const showMore = ref(false);
const isVisibleCreateReviewModal = ref(false);

const shownReviews = computed(() =>
  showMore.value ? props.reviews : props.reviews.slice(0, 3),
);
</script>

<template>
  <section class="productReviewsSection">
    <div class="productReviewsSection__header">
      <h2 class="productReviewsSection__title">{{ t('title') }}</h2>

      <BaseButton
        v-if="reviews.length > 3"
        outlined
        @click="showMore = !showMore"
        mobile-size="xs"
        class="productReviewsSection__more"
      >
        {{ showMore ? t('less') : t('more') }}
      </BaseButton>
    </div>

    <p v-if="!shownReviews.length" class="productReviewsSection__empty">
      {{ t('empty') }}
    </p>
    <BaseToggleTransition
      v-else
      :state="shownReviews.length"
      :duration="500"
      content-class="productReviewsSection__content"
    >
      <BaseReviewCard
        v-for="(item, index) in shownReviews"
        :key="index"
        :review="item"
      />
    </BaseToggleTransition>

    <div v-if="userStore.authorized" class="productReviewsSection__footer">
      <span class="productReviewsSection__question">
        {{ t('question') }}
      </span>
      <BaseButton
        @click="isVisibleCreateReviewModal = true"
        class="productReviewsSection__create"
      >
        {{ t('create_review') }}
      </BaseButton>
    </div>
    <div v-else class="productReviewsSection__footer">
      <span class="productReviewsSection__hint">
        {{ t('auth_hint') }}
      </span>
      <NuxtLink
        :to="{
          path: '/auth',
          query: { redirectUrl: `/product/${product.code}` },
        }"
      >
        <BaseButton
          @click="isVisibleCreateReviewModal = true"
          class="productReviewsSection__create"
        >
          {{ t('sign_in') }}
        </BaseButton>
      </NuxtLink>
    </div>
  </section>

  <ProductCreateReviewModal
    v-model:visible="isVisibleCreateReviewModal"
    :product="product"
  />
</template>

<i18n>
ru:
  title: Отзывы наших покупателей
  less: Скрыть
  more: Показать больше
  empty: У этого товара еще нет отзывов
  question: Понравился этот продукт?
  create_review: Оставьте отзыв
  auth_hint: Чтобы оставить отзыв, необходимо войти или зарегистрироваться
  sign_in: Войти

en:
  title: Reviews from our customers
  less: Hide
  more: Show more
  empty: This product has no reviews yet
  question: Did you like this product?
  create_review: Leave a review
  auth_hint: To leave a review, you need to sign in or register
  sign_in: Sign in
</i18n>

<style lang="scss">
.productReviewsSection {
  background-color: #8583ba;
  padding: 35px 20px 85px;
  display: flex;
  flex-direction: column;

  @include mq('sm') {
    padding: 40px 16px 50px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    @include mq('sm') {
      display: contents;
    }
  }

  &__title {
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.2px;
    margin: 0;
    color: #ffffff;

    @include mq('sm') {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 24px;
    }
  }

  &__more {
    @include mq('sm') {
      order: 1;
      margin-top: 40px;
    }
  }

  &__empty {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #fff;
    margin: 0;
    text-align: center;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 18px;
    grid-row-gap: 24px;

    @include mq('sm') {
      display: flex;
      flex-direction: column;
    }
  }

  &__footer {
    padding-top: 60px;

    @include mq('sm') {
      order: 2;
      padding-top: 40px;
    }
  }

  &__question {
    font-size: 56px;
    line-height: 64px;
    color: #ffffff;
    width: 100%;
    margin: 0 0 45px;
    text-align: center;
    display: block;

    @include mq('lg') {
      font-size: 44px;
      line-height: 120%;
    }

    @include mq('sm') {
      font-size: 30px;
      line-height: 38px;
      margin-bottom: 40px;
    }
  }

  &__hint {
    font-size: 34px;
    line-height: 40px;
    color: #ffffff;
    width: 100%;
    margin: 0 0 45px;
    text-align: center;
    display: block;

    @include mq('sm') {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 32px;
    }
  }

  &__create {
    margin: 0 auto;
    max-width: 100%;
  }
}
</style>
