<script lang="ts" setup>
import type { ProductType } from '~/api/common';
import placeholder from '~/assets/icons/placeholder.svg?url';

const TEST_ID = 'product-subscription';

defineOptions({ inheritAttrs: false });

const props = defineProps<{
  product: ProductType;
}>();
const attrs = useAttrs();
const checked = defineModel<boolean>('checked', { default: false });

const { t } = useI18n();
const subscribeProductsStore = useSubscribeProductsStore();

const isInfoModalVisible = ref(false);

const price = computed(() =>
  priceFormat(
    calculateSubscribeProductPrice(
      props.product.priceInfo.basePrice || props.product.priceInfo.price,
    ),
  ),
);
</script>

<template>
  <div class="productSubscription" :data-test-id="TEST_ID" v-bind="attrs">
    <label class="productSubscription__header">
      <BaseSwitch v-model="checked" :data-test-id="`${TEST_ID}-switch`" />
      <span class="productSubscription__title">
        {{ t('title', { discount: SUBSCRIPTION_DISCOUNT_VALUE }) }}
      </span>
    </label>

    <BaseCollapse :visible="checked">
      <div class="productSubscription__content">
        <div class="productSubscription__visual">
          <NuxtImg
            :src="product.picture || placeholder"
            width="142"
            format="webp"
            class="productSubscription__image"
          />
          <span class="productSubscription__discount">
            {{ t('discount', { discount: SUBSCRIPTION_DISCOUNT_VALUE }) }}
          </span>
        </div>

        <div class="productSubscription__info">
          <span class="productSubscription__main">
            {{ product.name }}
          </span>
          <span class="productSubscription__main">
            {{ price }}
            <span class="productSubscription__linethrough">
              {{
                priceFormat(
                  product.priceInfo.basePrice || product.priceInfo.price,
                )
              }}
            </span>
          </span>
          <div class="productSubscription__additional">
            <span>{{ t('frequency') }}</span>
            <span v-if="subscribeProductsStore.nextDate">
              {{
                t('next_delivery', { date: subscribeProductsStore.nextDate })
              }}
            </span>
          </div>
          <button
            @click="isInfoModalVisible = true"
            class="productSubscription__question"
          >
            {{ t('question') }}
          </button>
        </div>
      </div>
    </BaseCollapse>
  </div>

  <ClientOnly>
    <LazyProductSubscriptionInfoModal v-model:visible="isInfoModalVisible" />
  </ClientOnly>
</template>

<i18n>
ru:
  title: Ваша подписка на рефил
  discount: -{discount}%
  frequency: Доставляем раз в 3 месяца
  next_delivery: 'Приблизительная дата доставки: {date}'
  question: Как это работает?

en:
  title: Your subscription to the refil
  discount: -{discount}%
  frequency: Delivered every 3 months
  next_delivery: 'Estimated delivery date: {date}'
  question: How does it work?
</i18n>

<style lang="scss">
.productSubscription {
  border-radius: 10px;
  border: 1px solid #d0d0d0;

  &__header {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #121212;
    text-transform: uppercase;
    display: flex;
    align-items: flex-start;
    gap: 17px;
    padding: 20px;

    @include mq('sm') {
      gap: 15px;
      padding: 21px 15px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__title {
    margin-top: -1px;
    display: block;

    @include mq('md') {
      margin-top: -3px;
    }
  }

  &__content {
    padding: 24px 20px;
    display: flex;
    gap: 31px;
    border-top: 1px solid #d0d0d0;

    @include mq('md') {
      padding: 18px 16px;
      gap: 16px;
    }
  }

  &__visual {
    position: relative;
  }

  &__image {
    width: 142px;
    display: block;
    object-fit: cover;

    @include mq('sm') {
      width: 100px;
      aspect-ratio: 1 / 1;
    }
  }

  &__discount {
    position: absolute;
    width: 27px;
    height: 27px;
    background-color: #8ab844;
    border-radius: 50%;
    color: #fff;
    font-size: 9px;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 8px;
    left: 7px;

    @include mq('sm') {
      width: 20px;
      height: 20px;
      top: 6px;
      left: 5px;
      font-size: 7px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    max-width: 225px;
  }

  &__main {
    display: block;
    color: #121212;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;

    &:not(:first-child) {
      margin-top: 2px;
    }

    @include mq('sm') {
      font-size: 14px;
      line-height: 20px;

      &:not(:first-child) {
        margin-top: 0;
      }
    }
  }

  &__linethrough {
    margin-left: 6px;
    color: #c0c0c0;
    text-decoration: line-through;
  }

  &__additional {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-top: 10px;
    margin-top: auto;
    color: #121212;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;

    @include mq('sm') {
      font-size: 12px;
      line-height: 16px;
      gap: 2px;
    }
  }

  &__question {
    margin-top: 3px;
    display: block;
    background: none;
    padding: 0;
    border: none;
    color: #121212;
    width: fit-content;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-decoration: underline;
    text-underline-offset: 4px;
    cursor: pointer;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.7;
    }

    @include mq('sm') {
      font-size: 12px;
      margin-top: 30px;
    }
  }
}
</style>
