<script lang="ts" setup>
import type { ProductDetailsType } from '~/api/product';

const props = defineProps<{
  product: ProductDetailsType;
}>();

const { t } = useI18n();

const openedItemIndex = ref(0);

const enabled = computed(
  () =>
    !!(
      props.product.composition?.picturePath ||
      props.product.composition?.text ||
      props.product.composition?.compositions.length
    ),
);
const compositionText = computed(() => props.product.composition?.text);

const onToggleItemCollapsed = (index: number) => {
  openedItemIndex.value = openedItemIndex.value === index ? -1 : index;
};
</script>

<template>
  <section
    v-if="product.composition && enabled"
    class="productCompositionSection"
  >
    <NuxtImg
      v-if="product.composition.picturePath"
      format="webp"
      :sizes="IMAGE_SIZES.adaptiveHalfScreen"
      :src="product.composition.picturePath"
      :alt="product.composition.text || ''"
      class="productCompositionSection__image"
    />

    <div class="productCompositionSection__container content-container">
      <div class="productCompositionSection__content">
        <h2 class="productCompositionSection__title">{{ t('title') }}</h2>
        <p
          v-if="compositionText"
          class="productCompositionSection__description"
        >
          {{ compositionText }}
        </p>

        <div class="productCompositionSection__list">
          <template v-for="(item, index) in product.composition.compositions">
            <BaseExpandedBlock
              v-if="item.name"
              :key="index"
              @update:expanded="onToggleItemCollapsed(index)"
              :expanded="openedItemIndex === index"
              :title="item.name"
              :empty="!item.description"
              content-class="productCompositionSection__itemContent"
            >
              {{ item.description }}
            </BaseExpandedBlock>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<i18n>
ru:
  title: Состав

en:
  title: Composition
</i18n>

<style lang="scss">
.productCompositionSection {
  display: flex;
  position: relative;

  @include mq('sm') {
    flex-direction: column;
  }

  &__image {
    display: flex;
    width: 50%;
    height: 100svh;
    position: sticky;
    top: 0;
    bottom: 0;
    object-fit: cover;

    @include mq('sm') {
      width: 100%;
      height: auto;
      position: static;
    }
  }

  &__container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;

    @include mq('sm') {
      width: 100%;
      padding-bottom: 58px;
    }
  }

  &__content {
    max-width: 550px;
    width: 100%;
  }

  &__title {
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.2px;
    color: #121212;
    margin-top: 0;
    margin-bottom: 24px;

    @include mq('sm') {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 12px;
    }
  }

  &__description {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.2px;
    color: #121212;
    opacity: 0.4;
    margin-top: 0;
    margin-bottom: 57px;
    word-break: break-word;
    white-space: pre-wrap;

    @include mq('sm') {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 52px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 22px;
  }

  &__itemContent {
    white-space: pre-wrap;
  }
}
</style>
